import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`关于`}</h2>
    <p>{`我们将从事泛计算智能领域的探索: 智能系统、计算机视觉、自然语言处理、可微分编程、以及多个子领域的融合。`}</p>
    <blockquote>
      <p parentName="blockquote">{`智慧与意识，是最后的疆界`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      